
.swiper {
  width: 100%;
  height: 100%;
}

.swiperSlide {
  text-align: center;
  font-size: 18px;
  background:'transparent';

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.swiperSlide img {
  display: block;
  /* width: 100%; */
  /* height: 100%; */
  object-fit: fill;
  border-radius: 8px;
} 

.swiperSlide{
  width: 90% !important;
  max-width: 500px;  
}